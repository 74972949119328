<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <!-- <v-card-title>Basic</v-card-title> -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          dense
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Daftar Tabungan Anggota</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                @keyup.enter="searching()"
                append-icon="mdi-magnify"
                label="Kode / Nama anggota"
                single-line
                hide-details
              ></v-text-field>
              <v-btn text @click="searching()">Cari</v-btn>
              <v-spacer></v-spacer>

              <!-- <v-dialog v-model="dialog" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="resetForm"> Tambah </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="1" sm="1" md="2">
                          <v-btn color="primary" small @click="dialogPilihanggota = true"> Pilih Anggota </v-btn>
                          <input type="hidden" v-model="editedItem.id_anggota" />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            v-model="editedItem.kode_anggota"
                            label="Kode Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            readonly
                            v-model="editedItem.nama_anggota"
                            label="Nama Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            v-model="editedItem.jenis_upah_anggota"
                            label="Jenis Upah *"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <label for="">Program Pinjaman</label>
                          <v-autocomplete
                            v-model="editedItem.id_jenis_pinjaman"
                            :items="programpinjamans"
                            dense
                            item-text="text"
                            item-value="value"
                            outlined
                            @change="programPinjamanChange()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.besar_pinjam" label="Besar Pinjam *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.lama_angsuran" label="Lama Angsuran *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field readonly v-model="editedItem.persen_bunga" label="Bunga (%) *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <label for="">Tgl Pinjaman</label><br />
                          <input type="date" v-model="editedItem.tgl_pinjaman" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" :loading="progerty.save_loading" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->

              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
                      <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
              </v-dialog> -->
            </v-toolbar>
          </template>

          <template v-slot:item.no="{ item, index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.anggota="{ item }">
            {{ item.nama + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.besar_tabungan="{ item }">
            {{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}
          </template>
          <template v-slot:item.tabungan_dibuat="{ item }">
            {{ formatDate(item.tabungan_dibuat) }}
          </template>
          <template v-slot:item.aksi="{ item }">
            <!-- <v-icon small class="mr-2" @click="editItem(item)" title="Lihat"> {{ icons.mdiEye }} </v-icon> -->
            <router-link :to="{ name: 'detail-tabungan', params: { id_tabungan: item.id } }">
              <v-btn small color="primary">Detail</v-btn>
            </router-link>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="initialize"> Reload</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'

// import PilihAnggota from './pinjaman/PilihAnggota.vue'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  components: {
    // PilihAnggota,
  },
  setup() {
    return {
      icons: {
        mdiEye,
      },
    }
  },
  data: () => ({
    progerty: {
      save_loading: false,
    },
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Anggota', value: 'anggota', sortable: false },
      { text: 'Wilayah', value: 'wilayah', sortable: false },
      { text: 'Program', value: 'nama_jenis', sortable: false },
      { text: 'Besar Tabungan', value: 'besar_tabungan', sortable: false, align: 'right' },
      { text: 'Tgl Dibuat', value: 'tabungan_dibuat', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: false,
    options: {},

    editedIndex: -1,
    editedItem: {
      id_anggota: '',
      nama_anggota: '',
      kode_anggota: '',
      jenis_upah_anggota: '',
      id_jenis_pinjaman: '',
      besar_pinjam: 0,
      lama_angsuran: 0,
      besar_angsuran: 0,
      persen_bunga: 0,
      bunga: 0,
      tgl_pinjaman: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
    },
    defaultItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: '',
      jenis_upah: '',
      aktif: '',
    },
    programpinjamans: [],
    search: '',
    dialogPilihanggota: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Pinjaman' : 'Detail Pinjaman'
    },
    token() {
      return this.$store.getters.token
    },
    anggotaSelected() {
      return this.$store.getters.anggotaSelected
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },

    anggotaSelected() {
      // console.log()
      this.editedItem.id_anggota = this.anggotaSelected.id
      this.editedItem.kode_anggota = this.anggotaSelected.kode_anggota
      this.editedItem.nama_anggota = this.anggotaSelected.nama
      this.editedItem.jenis_upah_anggota = this.anggotaSelected.jenis_upah
      this.dialogPilihanggota = false
      this.getProgrampinjamans()
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    initialize() {
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true

      const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.search)
      form.append('page', page)
      form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/Tabungan/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    getProgrampinjamans() {
      if (this.editedItem.jenis_upah_anggota !== undefined) {
        this.$store.state.loadingOverlay = true

        // const { page, itemsPerPage } = this.options

        const headers = {
          Authorization: this.token,
        }
        // const form = new FormData()
        // form.append('search', this.search)
        // form.append('page', page)
        // form.append('itemsPerPage', itemsPerPage)
        axios
          .get(`${apiRoot}/api/JenisPinjaman/getByJenisupah/${this.editedItem.jenis_upah_anggota}`, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              response.data.data.forEach(element => {
                this.programpinjamans.push({
                  text: element.nama,
                  value: element.id,
                  lama_angsuran: element.lama_angsuran,
                  maks_pinjam: element.maks_pinjam,
                  bunga: element.bunga,
                })
              })
            } else {
              alert(response.data.message)
            }
            this.$store.state.loadingOverlay = false
          })
          .catch(error => {
            alert(error)
            this.$store.state.loadingOverlay = false
          })
      }
    },
    programPinjamanChange() {
      this.programpinjamans.forEach(element => {
        if (this.editedItem.id_jenis_pinjaman.value === element.id) {
          this.editedItem.lama_angsuran = element.lama_angsuran
          this.editedItem.besar_pinjam = element.maks_pinjam
          this.editedItem.persen_bunga = element.bunga
        }
      })
    },
    hitungAngsuran() {
      let angsuran = parseInt(this.editedItem.besar_pinjam) / parseInt(this.editedItem.lama_angsuran)
      this.editedItem.besar_angsuran = angsuran
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.jenis_upah = { text: item.jenis_upah, value: item.jenis_upah }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('tgl_acc', new Date().toJSON().slice(0, 10).replace(/-/g, '-'))
        form.append('besar_pinjam_acc', this.editedItem.besar_pinjam_acc)
        form.append('jangka_waktu_acc', this.editedItem.jangka_waktu_acc)
        axios
          .post(`${apiRoot}/api/Pengajuan/updatePengajuan`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              // this.search = ''
              // this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        this.progerty.save_loading = true

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_anggota', this.editedItem.id_anggota)
        form.append('id_jenis_pinjaman', this.editedItem.id_jenis_pinjaman)
        form.append('besar_pinjam', this.editedItem.besar_pinjam)
        form.append('lama_angsuran', this.editedItem.lama_angsuran)
        form.append('persen_bunga', this.editedItem.persen_bunga)
        form.append('jenis_upah', this.editedItem.jenis_upah_anggota)
        form.append('tgl_pinjaman', this.editedItem.tgl_pinjaman)
        axios
          .post(`${apiRoot}/api/Pinjaman/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.progerty.save_loading = false
          })
          .catch(error => {
            alert(error)
            this.progerty.save_loading = false
          })
      }
    },

    aktifCheck() {},
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
    angsur() {},
    resetForm() {
      this.$store.state.anggotaSelected = {}
    },
  },
}
</script>
